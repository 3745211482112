body {
  margin: 0;
  padding: 0;
  font-family: "Poppins";
}

a {
  text-decoration: none;
  color: #000;
}

p {
  font-size: 15px;
}

.blocks {
  display: flex;
}

.first-block {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../img/hero/iggirezekne_hero.jpg);
  background-position: center right -150px;
  background-size: 200vh;
  transition: background-size 0.2s ease-in-out;
}

.first-block:hover {
  background-size: 205vh;
}

.second-block {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../img/hero/osteria_hero.jpg);
  background-size: 100vh;
  background-position: center right -220px;
  transition: background-size 0.2s ease-in-out;
}
.second-block .block-logo {
  width: 270px !important;
}

.second-block:hover {
  background-size: 155vh;
}

.third-block {
  background-image: linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(../img/hero/iggidaugavpils_hero.jpeg);
  background-size: 100vh;
  background-position: center;
  transition: background-size 0.2s ease-in-out;
}

.third-block:hover {
  background-size: 105vh;
}

.block {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  position: relative;
  background-repeat: no-repeat;
  background-color: black;
  overflow: hidden;
}
.block .block-text {
  color: #fff;
  padding: 40px;
  bottom: 0;
}
.block .block-text h3 {
  font-weight: 500;
  margin: 0;
  font-size: 24px;
}
.block .block-text p {
  margin: 0;
  font-size: 17px;
}
.block .block-text div {
  display: flex;
  justify-content: space-between;
}
.block .block-logo {
  height: auto;
  width: 220px;
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}
.block .block-construction {
  height: 120%;
  width: 100px;
  position: absolute;
  background-color: red;
  top: 0;
  right: 0;
  transform: translate(50%) rotate(26deg);
  transform-origin: top right;
}

.restHeader {
  display: flex;
  justify-content: center;
  margin-top: 70px;
  margin-bottom: 85px;
}
.restHeader .headerContent {
  width: 650px;
}
.restHeader .headerContent .backLink {
  outline: none;
  background: none;
  border: none;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 15px;
}
.restHeader .headerContent .backLink img {
  width: 18px;
}
.restHeader .headerContent .contentText {
  margin-top: 50px;
}
.restHeader .headerContent .contentText h2 {
  font-weight: 600;
  line-height: 25px;
  margin: 0;
}
.restHeader .headerContent .contentText .contacts {
  display: flex;
  gap: 60px;
  margin-top: 45px;
}
.restHeader .headerContent .contentText .contacts div {
  display: flex;
  align-items: flex-start;
  gap: 15px;
}
.restHeader .headerContent .contentText .contacts div img {
  width: 23px;
}
.restHeader .headerContent .contentText .contacts div p {
  line-height: 20px;
  margin: 0;
}
.restHeader .headerContent .contentText .description {
  margin-top: 40px;
  text-align: justify;
}
.restHeader .headerContent .socials {
  display: flex;
  align-items: center;
  gap: 75px;
  margin-top: 35px;
}
.restHeader .headerContent .socials .socialIcon {
  display: flex;
  align-items: center;
  gap: 15px;
}
.restHeader .headerContent .socials .socialIcon img {
  height: 20px;
}

.imgGallery {
  display: grid;
  grid-template-columns: auto auto auto;
}
.imgGallery .imgDiv {
  width: auto;
  height: 570px;
  overflow: hidden;
}
.imgGallery .imgDiv img {
  object-fit: cover;
  height: 100%;
  width: 100%;
  min-width: 570px;
}

.footer {
  height: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.footer .footerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.footer .footerContent p {
  opacity: 0.4;
  text-align: center;
  padding: 0 10px;
  margin-bottom: 7px;
}

.container {
  margin-top: 120px;
  display: flex;
  justify-content: center;
}
.container .homepageContent {
  display: flex;
  align-items: center;
  gap: 50px;
  padding: 0 80px;
  max-width: 1400px;
  width: 100%;
}
.container .homepageContent .homepageText {
  width: 100%;
}
.container .homepageContent .homepageText h2 {
  text-align: left;
  font-weight: 600;
  margin-bottom: 30px;
  font-size: 35px;
}
.container .homepageContent .homepageText p {
  text-align: justify;
  opacity: 0.8;
}
.container .homepageContentImg .homepageText {
  width: 70%;
}
.container .homepageContentImg .homepageImg {
  width: 30%;
  height: 550px;
  border-radius: 10px;
  overflow: hidden;
}
.container .homepageContentImg .homepageImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: top;
}

.bus {
  padding: 0 80px;
  max-width: 1400px;
  width: 100%;
}
.bus .busTop {
  display: flex;
  gap: 50px;
  width: 100%;
  margin-bottom: 20px;
}
.bus .busTop .busText {
  width: 100%;
  padding-bottom: 20px;
  text-align: justify;
}
.bus .busTop .busText h2 {
  text-align: left;
  font-weight: 600;
  margin-bottom: 30px;
}
.bus .busTop .topImg {
  width: 50.2%;
  display: block;
}
.bus .busTop .topImg img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}
.bus .busImgs {
  width: 100%;
  overflow-x: hidden;
  display: flex;
  gap: 20px;
}
.bus .busImgs .busMainImg {
  display: none;
}
.bus .busImgs img {
  width: 50%;
  height: 450px;
  object-fit: cover;
  object-position: center;
}

.busContacts {
  display: flex;
  justify-content: start;
  width: 100%;
  margin-top: 40px;
}
.busContacts div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.busContacts div img {
  width: 23px;
}

.united {
  display: flex;
  align-items: start;
  gap: 50px;
  padding: 0 80px;
  max-width: 1400px;
  width: 100%;
}
.united .unitedImg {
  width: 40%;
  overflow: hidden;
}
.united .unitedImg img {
  border-radius: 10px;
  height: 550px;
  width: 100%;
  object-fit: cover;
  object-position: center !important;
}
.united .unitedText {
  padding-top: 20px;
  width: 60%;
  position: sticky;
  top: 30px;
}
.united .unitedText h2 {
  text-align: left;
  font-weight: 600;
  margin-bottom: 30px;
  font-size: 35px;
}
.united .unitedText p {
  text-align: justify;
  opacity: 0.8;
}

.accordionItem {
  border-bottom: 1px solid #e7e7e7;
}

.accordionHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
  padding: 5px 0;
  cursor: pointer;
  font-family: "Poppins";
}
.accordionHeader h3 {
  font-weight: 500;
  font-size: 16px;
  color: #000;
}
.accordionHeader img {
  width: 30px;
  opacity: 0.6;
  transition: transform 0.2s ease-in-out;
}

.accordionBtn {
  width: 100%;
  background: none;
  border: none;
  outline: none;
  text-align: left;
  padding: 0 10px;
}

.accordionBtnActive {
  background-color: #fafafa;
}
.accordionBtnActive .accordionHeader img {
  transform: rotate(180deg);
}

.pdfBtns {
  width: 100%;
  display: flex;
  gap: 20px;
  margin-top: 35px;
}
.pdfBtns a {
  width: 100%;
}

.restMenuBtn {
  width: 100%;
  text-align: center;
  padding: 15px;
  color: #000;
  border: 1px solid #000;
  background: none;
  outline: none;
  border-radius: 5px;
  font-family: "Poppins";
  font-size: 15px;
  cursor: pointer;
}
.restMenuBtn:hover {
  background-color: #fafafa;
}

.topbar {
  position: absolute;
  right: 0;
  z-index: 100;
  float: right;
  display: flex;
  justify-content: flex-end;
}
.topbar .topbarContent {
  padding: 10px 15px;
  display: flex;
  align-items: center;
  gap: 20px;
}
.topbar .topbarContent img {
  cursor: pointer;
  width: 40px;
}

.sidenav {
  position: fixed;
  z-index: 101;
  height: 100vh;
  width: 100vw;
  display: none;
}
.sidenav .sidenavOverlay {
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
}
.sidenav .sidenavContent {
  background-color: #fff;
  position: absolute;
  right: 0;
  width: 350px;
  height: 100vh;
}
.sidenav .sidenavContent .sidenavHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 20px;
}
.sidenav .sidenavContent .sidenavHeader img {
  width: 40px;
  cursor: pointer;
}
.sidenav .sidenavContent nav {
  margin-top: 40px;
}
.sidenav .sidenavContent nav ul {
  list-style-type: none;
  text-align: right;
  padding: 0 50px;
}
.sidenav .sidenavContent nav ul li {
  font-size: 27px;
  font-weight: 500;
  cursor: pointer;
}
.sidenav .sidenavContent nav ul li:not(:last-child) {
  margin-bottom: 30px;
}

.sidenavActive {
  display: block !important;
}

.tableStatus {
  position: absolute;
  background-color: #fff;
  border-radius: 100px;
  top: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  height: 40px;
  min-width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
  padding: 0 20px;
}
.tableStatus .tableIcon {
  width: 25px !important;
  opacity: 0.9;
}
.tableStatus .statusContent {
  display: flex;
  align-items: center;
  gap: 8px;
}
.tableStatus .statusContent .statusText {
  font-weight: 500;
  font-size: 15px;
  white-space: nowrap;
}
.tableStatus .blob {
  position: relative;
  width: 8px;
  height: 8px;
  min-width: 8px;
  max-height: 8px;
  border-radius: 50%;
}
.tableStatus .green_blob {
  background: #27ae60;
  box-shadow: 0 0 0 0 #27ae60;
  animation: pulse-green 2s infinite;
}
.tableStatus .red_blob {
  background: #e41e0c;
  box-shadow: 0 0 0 0 #e41e0c;
  animation: pulse-red 2s infinite;
}
@keyframes pulse-red {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 0 0 0 0 rgba(228, 30, 12, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(228, 30, 12, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(228, 30, 12, 0);
  }
}
@keyframes pulse-green {
  0% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(39, 174, 96, 0.7);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 10px rgba(228, 30, 12, 0);
  }
  100% {
    transform: scale(0.95);
    box-shadow: 0 0 0 0 rgba(228, 30, 12, 0);
  }
}

.spinner {
  width: 20px;
  height: 20px;
  position: relative;
  margin: 100px auto;
}

.double-bounce1, .double-bounce2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background-color: #a4a4a4;
  opacity: 0.6;
  position: absolute;
  top: 0;
  left: 0;
  -webkit-animation: sk-bounce 2s infinite ease-in-out;
  animation: sk-bounce 2s infinite ease-in-out;
}

.double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}

@-webkit-keyframes sk-bounce {
  0%, 100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}
@keyframes sk-bounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
.contentHeader {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
}

.tableStatusRestPage .tableStatus {
  margin-top: -8px;
  position: static;
  transform: none;
}

@media only screen and (max-width: 600px) {
  .blocks {
    flex-direction: column;
  }

  .block {
    height: 100%;
    min-height: 400px;
  }
  .block .block-logo {
    width: 150px !important;
  }
  .block .block-text {
    padding: 20px;
  }
  .block .block-text h3 {
    font-size: 20px;
  }
  .block .block-text p {
    font-size: 16px;
  }

  .first-block {
    background-size: 150vh;
  }
  .first-block:hover {
    background-size: 155vh;
  }

  .second-block {
    background-position: center bottom;
    background-size: 70vh;
  }
  .second-block .block-logo {
    width: 200px !important;
  }
  .second-block:hover {
    background-size: 75vh;
  }

  .third-block {
    background-size: 60vh;
  }
  .third-block:hover {
    background-size: 65vh;
  }

  .pdfBtns {
    flex-direction: column;
  }
}
@media only screen and (max-width: 660px) {
  .imgGallery {
    grid-template-columns: auto !important;
  }

  .imgGallery .imgDiv {
    min-width: auto !important;
  }
  .imgGallery .imgDiv img {
    min-width: auto !important;
  }

  .restHeader {
    padding: 0 30px;
  }
  .restHeader .headerContent {
    width: 100vw !important;
  }
  .restHeader .headerContent .contentText .contacts {
    flex-direction: column;
    gap: 30px;
  }

  .contentHeader {
    flex-direction: column;
    gap: 30px;
  }

  .socials {
    flex-direction: column;
    gap: 25px !important;
    align-items: flex-start !important;
  }

  .container .homepageContent {
    padding: 0 30px;
    width: 100% !important;
  }
  .container .homepageContentImg .homepageImg {
    width: 100%;
    height: 400px !important;
    order: 2;
  }
  .container .united {
    padding: 0 30px;
    width: 100% !important;
  }
  .container .united .unitedImg {
    width: 100%;
    order: 1;
  }
  .container .united .unitedText {
    width: 100%;
    position: static;
  }
  .container .bus {
    padding: 0 30px;
    width: 100% !important;
  }
}
@media only screen and (max-width: 1300px) and (min-width: 600px) {
  .blocks {
    flex-direction: column;
  }

  .block {
    height: 100%;
    min-height: 500px;
  }
  .block .block-logo {
    width: 180px !important;
  }
  .block .block-text h3 {
    font-size: 22px;
  }
  .block .block-text p {
    font-size: 17px;
  }

  .second-block {
    background-size: 100vh;
    background-position: center bottom;
  }
  .second-block .block-logo {
    width: 250px !important;
  }
  .second-block:hover {
    background-size: 105vh;
  }

  .third-block {
    background-size: 90vh;
    background-position: center right;
  }
  .third-block:hover {
    background-size: 95vh;
  }
}
@media only screen and (min-width: 900px) {
  .second-block {
    background-size: 105vh;
  }
  .second-block:hover {
    background-size: 110vh;
  }

  .third-block {
    background-size: 110vh;
  }
  .third-block:hover {
    background-size: 115vh;
  }

  .container .homepageContentImg .homepageText {
    width: 60%;
  }
  .container .homepageContentImg .homepageImg {
    width: 40%;
  }
}
@media only screen and (min-width: 1100px) {
  .second-block {
    background-size: 125vh;
  }
  .second-block:hover {
    background-size: 130vh;
  }

  .third-block {
    background-size: 125vh;
  }
  .third-block:hover {
    background-size: 130vh;
  }
}
@media only screen and (max-width: 1100px) {
  .imgGallery {
    grid-template-columns: auto auto;
  }

  .container .homepageContentImg {
    display: flex;
    flex-direction: column;
  }
  .container .homepageContentImg .homepageText {
    width: 100%;
  }
  .container .homepageContentImg .homepageImg {
    width: 100%;
    height: 600px;
    order: 2;
  }
  .container .united {
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }
  .container .united .unitedText {
    width: 100%;
    position: static;
  }
  .container .united .unitedImg {
    order: 1;
    width: 100%;
    display: flex;
    overflow-x: scroll;
    gap: 10px;
  }
  .container .united .unitedImg img {
    min-width: 560px;
  }
  .container .bus {
    overflow: hidden;
  }
  .container .bus .busTop {
    flex-direction: column;
  }
  .container .bus .busTop .busText {
    width: 100%;
    margin-top: 0px;
    padding-bottom: 20px;
  }
  .container .bus .busTop .busText .busContacts {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .container .bus .busTop .busText .busContacts div {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  .container .bus .busTop .busText .busContacts div img {
    width: 23px;
  }
  .container .bus .busTop .busText h2 {
    text-align: left;
    font-weight: 600;
    margin-bottom: 30px;
  }
  .container .bus .busTop .topImg {
    width: 50.2%;
    display: none;
  }
  .container .bus .busTop .topImg img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
  }
  .container .bus .busImgs {
    overflow-x: scroll;
    gap: 10px;
  }
  .container .bus .busImgs .busMainImg {
    display: block;
  }
  .container .bus .busImgs img {
    min-width: 560px;
  }
}
.scrollBounce {
  animation: scrollBounce 0.5s;
}

@keyframes scrollBounce {
  0% {
    transform: scrollLeft(0);
  }
  25% {
    transform: scrollLeft(-20px);
  }
  50% {
    transform: scrollLeft(-70px);
  }
  75% {
    transform: scrollLeft(-20px);
  }
  100% {
    transform: scrollLeft(0);
  }
}
.langSelect {
  background: none;
  outline: none;
  border: none;
  color: white;
  font-family: "Poppins";
  font-size: 18px;
  font-weight: 500;
  -webkit-appearance: none;
  /* Safari and Chrome */
  -moz-appearance: none;
  /* Firefox */
  appearance: none;
  box-sizing: border-box;
  cursor: pointer;
  text-align: right;
  padding: 6px 0;
}

.langSelectFooter {
  margin-bottom: 20px;
  border: none;
  outline: none;
  background: none;
  color: rgba(0, 0, 0, 0.6);
  font-family: "Poppins";
  font-size: 15px;
}